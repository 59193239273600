var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

/**
 * @license
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
(function () {
  'use strict';
  /**
   * Class constructor for Progress MDL component.
   * Implements MDL component design pattern defined at:
   * https://github.com/jasonmayes/mdl-component-design-pattern
   *
   * @constructor
   * @param {HTMLElement} element The element that will be upgraded.
   */

  var MaterialProgress = function MaterialProgress(element) {
    (this || _global).element_ = element; // Initialize instance.

    this.init();
  };

  window["MaterialProgress"] = MaterialProgress;
  /**
   * Store constants in one place so they can be updated easily.
   *
   * @enum {string | number}
   * @private
   */

  MaterialProgress.prototype.Constant_ = {};
  /**
   * Store strings for class names defined by this component that are used in
   * JavaScript. This allows us to simply change it in one place should we
   * decide to modify at a later date.
   *
   * @enum {string}
   * @private
   */

  MaterialProgress.prototype.CssClasses_ = {
    INDETERMINATE_CLASS: "mdl-progress__indeterminate"
  };
  /**
   * Set the current progress of the progressbar.
   *
   * @param {number} p Percentage of the progress (0-100)
   * @public
   */

  MaterialProgress.prototype.setProgress = function (p) {
    if ((this || _global).element_.classList.contains((this || _global).CssClasses_.INDETERMINATE_CLASS)) {
      return;
    }

    (this || _global).progressbar_.style.width = p + "%";
  };

  MaterialProgress.prototype["setProgress"] = MaterialProgress.prototype.setProgress;
  /**
   * Set the current progress of the buffer.
   *
   * @param {number} p Percentage of the buffer (0-100)
   * @public
   */

  MaterialProgress.prototype.setBuffer = function (p) {
    (this || _global).bufferbar_.style.width = p + "%";
    (this || _global).auxbar_.style.width = 100 - p + "%";
  };

  MaterialProgress.prototype["setBuffer"] = MaterialProgress.prototype.setBuffer;
  /**
   * Initialize element.
   */

  MaterialProgress.prototype.init = function () {
    if ((this || _global).element_) {
      var el = document.createElement("div");
      el.className = "progressbar bar bar1";

      (this || _global).element_.appendChild(el);

      (this || _global).progressbar_ = el;
      el = document.createElement("div");
      el.className = "bufferbar bar bar2";

      (this || _global).element_.appendChild(el);

      (this || _global).bufferbar_ = el;
      el = document.createElement("div");
      el.className = "auxbar bar bar3";

      (this || _global).element_.appendChild(el);

      (this || _global).auxbar_ = el;
      (this || _global).progressbar_.style.width = "0%";
      (this || _global).bufferbar_.style.width = "100%";
      (this || _global).auxbar_.style.width = "0%";

      (this || _global).element_.classList.add("is-upgraded");
    }
  }; // The component registers itself. It can assume componentHandler is available
  // in the global scope.


  componentHandler.register({
    constructor: MaterialProgress,
    classAsString: "MaterialProgress",
    cssClass: "mdl-js-progress",
    widget: true
  });
})();

export default {};